<template>
  <el-menu class="el-menu-vertical-demo" mode="vertical" :unique-opened="true" :collapse-transition="false"
    :default-active="activeIndex" background-color="rgba(0,0,0,0)" text-color="#fff" style="border: none"
    :collapse="isCollapse">
    <template v-for="(item, index) in menus">
      <template v-if="item.position != 'top'">
        <template v-if="item.children && item.children.length > 0">
          <VerMenuEleItem :menuObj="item" :key="index" v-if="item.status === 1"></VerMenuEleItem>
        </template>
        <template v-else>
          <el-menu-item v-if="item.status === 1" :index="item.marking" :key="item.marking" @click.native="jump(item)"
            style="padding-left: 20px">
            <i :class="`iconfont ${item.icon}`"></i>
            <span slot="title">
              {{ item.name }}
            </span>
          </el-menu-item>
        </template>
      </template>
    </template>
  </el-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VerMenuEle',
  components: {
    VerMenuEleItem: () => import('./VerMenuEleItem.vue'),
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      menus: (state) => state.user.menus,
    }),
  },
  data() {
    return {
      activeIndex: this.$route.name,
    }
  },
  watch: {
    $route(to, from) {
      this.activeIndex = to.name
    },
  },
  created() { },
  methods: {
    jump(item) {
      var path = `/${item.marking}`
      this.$router.push(path)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/config.scss';

.el-menu-vertical-demo {
  color: #fff;

  &:not(.el-menu--collapse) {
    width: 100%;
  }

  * {
    &:hover {
      background: none !important;
    }
  }

  /deep/ .iconfont {
    vertical-align: middle;
    margin-right: 8px;
    width: 24px;
    text-align: center;
    font-size: 22px;
    display: inline-block;
    font-weight: lighter;
  }

  /deep/ .el-submenu__title {
    padding-left: 0 !important;
    padding-right: 40px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    margin-bottom: 1px;
    background: none !important;

    i {
      color: #f2f3f5;
    }
  }

  /deep/ .el-menu-item-group__title {
    padding-top: 0;
    padding-bottom: 0;
  }

  /deep/ .el-menu-item {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    margin-bottom: 1px;
    color: #f2f3f5;

    i {
      color: #f2f3f5;
    }

    &:hover {}

    &.is-active {
      background: #444 !important;
    }
  }
}
</style>
